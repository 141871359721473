export const name = 'tpa' as const
export const TpaEventsListenerManagerSymbol = Symbol('TpaEventsListenerManager')
export const TpaPopupApiSymbol = Symbol('TpaPopupApi')
export const TpaPopupSymbol = Symbol('TpaPopup')
export const TpaModalSymbol = Symbol('TpaModal')
export const TpaFullScreenModeSymbol = Symbol('TpaFullScreenMode')
export const TpaSymbol = Symbol('Tpa')
export const TpaComponentApiSymbol = Symbol('TpaComponentApi')
export const IFrameStartedLoadingReporterSymbol = Symbol('IFrameStartedLoadingReporter')
export const SiteMapSymbol = Symbol('SiteMap')
