import type { ViewerModel } from '@wix/thunderbolt-symbols'
import { LoginOptions } from 'feature-site-members'

type ViewerModelSite = ViewerModel['site']

export type WixEmbedsAPIFeatureState = {
	listeners: {
		[eventName: string]: Array<Function>
	}
	firstMount: boolean
}

export interface WixEmbedsAPI {
	getMetaSiteId(): ViewerModelSite['metaSiteId']
	getHtmlSiteId(): ViewerModelSite['siteId']
	getExternalBaseUrl(): ViewerModelSite['externalBaseUrl']
	isWixSite(): boolean
	getLanguage(): ViewerModel['language']['siteLanguage']
	getCurrentPageId(): string | undefined

	getAppToken(appDefId: string): string | undefined

	registerToEvent(eventName: string, callback: Function): void
	unregisterFromEvent(eventName: string, callback: Function): void
	promptLogin(loginOptions: Partial<LoginOptions> & { onSuccess: () => {}; onError: () => {} }): void
}

export enum LoginErrorDetails {
	'missingMembersArea' = 'Missing Memebers Area',
	'unknown' = 'Unknown',
}
declare global {
	interface Window {
		wixEmbedsAPI: WixEmbedsAPI
	}
}
