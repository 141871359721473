import { IStructureStore, Structure, IBaseStructureAPI, IStructureAPI } from '@wix/thunderbolt-symbols'
import { withDependencies } from '@wix/thunderbolt-ioc'
import { BaseStructureAPISym } from './symbols'

const dsStructureAPI = (structureStore: IStructureStore, baseStructureAPI: IBaseStructureAPI): IStructureAPI => {
	return {
		...baseStructureAPI,
		addPageAndRootToRenderedTree: (pageId: string, contextId: string) => {
			const sitePages = structureStore.get('SITE_PAGES')
			const wrapperId = baseStructureAPI.getPageWrapperComponentId(pageId, contextId)
			const pageBgId = `pageBackground_${pageId}`
			const hasPageBackground = structureStore.get(pageBgId)
			const mainMF = structureStore.get('main_MF')
			const rootComponents = [
				'SITE_STYLES',
				'SCROLL_TO_TOP',
				'site-root',
				'DYNAMIC_STRUCTURE_CONTAINER',
				'SCROLL_TO_BOTTOM',
				'FONTS',
			]
			if (hasPageBackground) {
				rootComponents.splice(1, 0, 'BACKGROUND_GROUP')
			}

			structureStore.update({
				main_MF: {
					...mainMF,
					components: rootComponents,
				},
				SITE_PAGES: {
					...sitePages,
					components: [wrapperId],
				},
				[wrapperId]: { componentType: 'PageMountUnmount', components: [pageId] },
				...(hasPageBackground && {
					BACKGROUND_GROUP: {
						componentType: 'BackgroundGroup',
						components: [pageBgId],
					},
				}),
			})
		},
		addShellStructure: async () => Promise.resolve(),
	}
}

export const DsStructureAPI = withDependencies([Structure, BaseStructureAPISym], dsStructureAPI)
