import { ILanguage } from '@wix/thunderbolt-symbols'
import { ALL_LANGUAGE_CODES } from './constants'

const languageLoader: Record<keyof typeof ALL_LANGUAGE_CODES, () => Promise<any>> = {
	ar: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_ar.json' /* webpackChunkName: "santa-langs-ar" */
		),
	bg: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_bg.json' /* webpackChunkName: "santa-langs-bg" */
		),
	zh: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_zh.json' /* webpackChunkName: "santa-langs-zh" */
		),
	cs: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_cs.json' /* webpackChunkName: "santa-langs-cs" */
		),
	da: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_da.json' /* webpackChunkName: "santa-langs-da" */
		),
	nl: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_nl.json' /* webpackChunkName: "santa-langs-nl" */
		),
	fi: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_fi.json' /* webpackChunkName: "santa-langs-fi" */
		),
	fr: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_fr.json' /* webpackChunkName: "santa-langs-fr" */
		),
	de: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_de.json' /* webpackChunkName: "santa-langs-de" */
		),
	el: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_el.json' /* webpackChunkName: "santa-langs-el" */
		),
	he: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_he.json' /* webpackChunkName: "santa-langs-he" */
		),
	hi: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_hi.json' /* webpackChunkName: "santa-langs-hi" */
		),
	hu: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_hu.json' /* webpackChunkName: "santa-langs-hu" */
		),
	id: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_id.json' /* webpackChunkName: "santa-langs-id" */
		),
	it: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_it.json' /* webpackChunkName: "santa-langs-it" */
		),
	ja: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_ja.json' /* webpackChunkName: "santa-langs-ja" */
		),
	jp: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_ja.json' /* webpackChunkName: "santa-langs-ja" */
		),
	ko: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_ko.json' /* webpackChunkName: "santa-langs-ko" */
		),
	kr: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_ko.json' /* webpackChunkName: "santa-langs-ko" */
		),
	lt: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_lt.json' /* webpackChunkName: "santa-langs-lt" */
		),
	ms: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_ms.json' /* webpackChunkName: "santa-langs-ms" */
		),
	no: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_no.json' /* webpackChunkName: "santa-langs-no" */
		),
	pl: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_pl.json' /* webpackChunkName: "santa-langs-pl" */
		),
	pt: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_pt.json' /* webpackChunkName: "santa-langs-pt" */
		),
	ro: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_ro.json' /* webpackChunkName: "santa-langs-ro" */
		),
	ru: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_ru.json' /* webpackChunkName: "santa-langs-ru" */
		),
	es: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_es.json' /* webpackChunkName: "santa-langs-es" */
		),
	sv: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_sv.json' /* webpackChunkName: "santa-langs-sv" */
		),
	tl: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_tl.json' /* webpackChunkName: "santa-langs-tl" */
		),
	th: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_th.json' /* webpackChunkName: "santa-langs-th" */
		),
	tr: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_tr.json' /* webpackChunkName: "santa-langs-tr" */
		),
	uk: () =>
		import(
			'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_uk.json' /* webpackChunkName: "santa-langs-uk" */
		),
}

export const getTranslation = (userLanguage: ILanguage['userLanguage']) => {
	if (userLanguage in languageLoader) {
		// @ts-ignore
		const languageLoaderStringRecord: Record<string, () => Promise<any>> = languageLoader
		return languageLoaderStringRecord[userLanguage.toString()]()
	}

	return import(
		'@wix/santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_en.json' /* webpackChunkName: "santa-langs-en" */
	)
}
