import { multi, named, optional, withDependencies } from '@wix/thunderbolt-ioc'
import {
	CurrentRouteInfoSymbol,
	IAppWillRenderFirstPageHandler,
	IPageWillUnmountHandler,
	IStructureAPI,
	LifeCycle,
	SiteFeatureConfigSymbol,
	StructureAPI,
	BISymbol,
	BIReporter,
	LoggerSymbol,
	ILogger,
	MasterPageFeatureConfigSymbol,
	InternalNavigationType,
	BrowserWindowSymbol,
	BrowserWindow,
} from '@wix/thunderbolt-symbols'
import { resolveUrl } from './resolveUrl'
import {
	name,
	RoutingMiddleware,
	UrlHistoryManagerSymbol,
	CustomUrlMiddlewareSymbol,
	PageJsonFileNameMiddlewareSymbol,
	CustomNotFoundPageMiddlewareSymbol,
} from './symbols'
import type {
	ICurrentRouteInfo,
	IRoutingSyncMiddleware,
	IRoutingMiddleware,
	IRouter,
	IRoutingConfig,
	RouteInfo,
	IUrlHistoryManager,
	RouterMasterPageConfig,
	NavigationParams,
	CandidateRouteInfo,
} from './types'
import { IPageProvider, PageProviderSymbol, IPageInitializer, PageInitializerSymbol } from 'feature-pages'
import { isSSR, taskify } from '@wix/thunderbolt-commons'
import { reportNavigationEnd, reportNavigationStart } from './navigationMonitoring'
import { NavigationManagerSymbol, INavigationManager } from 'feature-navigation-manager'
import { removeQueryParams } from './urlUtils'

const emptyMiddleware: IRoutingMiddleware = {
	handle: async (routeInfo) => routeInfo,
}

const emptySyncMiddleware: IRoutingSyncMiddleware = {
	handleSync: (routeInfo) => routeInfo,
}

const getContextId = ({ type, pageId, relativeUrl }: CandidateRouteInfo): string => {
	const [, additionalRoute] = relativeUrl?.match(/\.\/.*?\/(.*$)/) || []
	return type === 'Dynamic' && additionalRoute ? `${pageId}_${additionalRoute}` : pageId
}

const notifyPageWillUnmount = async (routeInfo: RouteInfo, pageProvider: IPageProvider) => {
	const { contextId, pageId } = routeInfo
	const pageReflector = await pageProvider(contextId, pageId)
	const handlers = pageReflector.getAllImplementersOf<IPageWillUnmountHandler>(LifeCycle.PageWillUnmountHandler)
	await Promise.all(handlers.map((handler) => handler.pageWillUnmount({ pageId, contextId })))
}

const routerFactory = (
	routingConfig: IRoutingConfig,
	routingMasterPageConfig: RouterMasterPageConfig,
	structureApi: IStructureAPI,
	pageProvider: IPageProvider,
	navigationManager: INavigationManager,
	pageJsonFileNameMiddleware: IRoutingMiddleware = emptyMiddleware,
	customNotFoundPageMiddleware: IRoutingMiddleware = emptyMiddleware,
	customUrlMiddleware: IRoutingSyncMiddleware = emptySyncMiddleware,
	dynamicRoutingMiddleware: IRoutingMiddleware = emptyMiddleware,
	protectedRoutingMiddleware: IRoutingMiddleware = emptyMiddleware,
	BlockingDialogsRoutingMiddleware: IRoutingMiddleware = emptyMiddleware,
	{ initPage }: IPageInitializer,
	appWillRenderFirstPageHandlers: Array<IAppWillRenderFirstPageHandler>,
	currentRouteInfo: ICurrentRouteInfo,
	urlHistoryManager: IUrlHistoryManager,
	biReporter: BIReporter,
	logger: ILogger,
	window: BrowserWindow
): IRouter => {
	const convertToFullUrl = (url: string, removeSearchParams: boolean) => {
		const isHomePageUrl = url === routingConfig.baseUrl || url === './'
		const candidateUrl = isHomePageUrl ? routingConfig.baseUrl : url
		const fullUrl = new URL(candidateUrl, `${routingConfig.baseUrl}/`)

		return removeSearchParams ? removeQueryParams(fullUrl.href) : fullUrl.href
	}

	const handleStaticRoute = async (routeInfo: CandidateRouteInfo) => {
		currentRouteInfo.updateCurrentRouteInfo(routeInfo)
		urlHistoryManager.pushUrlState(routeInfo.parsedUrl)

		const { contextId, pageId } = routeInfo
		await initPage({ pageId, contextId })
		if (navigationManager.isFirstNavigation()) {
			await Promise.all(
				appWillRenderFirstPageHandlers.map((handler) =>
					taskify(() => handler.appWillRenderFirstPage({ pageId, contextId }))
				)
			)
		}

		await structureApi.addPageAndRootToRenderedTree(pageId, contextId)
		return routeInfo
	}

	const scrollToTop = () => {
		if (!isSSR(window)) {
			window!.scrollTo({ top: 0 })
			const targetElement = window!.document.getElementById('SCROLL_TO_TOP')
			// eslint-disable-next-line no-unused-expressions
			targetElement?.focus()
		}
	}

	const handleSamePageNavigation = async (
		currentRoute: RouteInfo | null,
		finalRouteInfo: CandidateRouteInfo,
		navigationParams?: NavigationParams
	) => {
		currentRouteInfo.updateCurrentRouteInfo(finalRouteInfo)
		urlHistoryManager.pushUrlState(finalRouteInfo.parsedUrl, navigationParams?.skipHistory)

		const shouldScrollToTop = !navigationParams?.disableScrollToTop
		if (shouldScrollToTop) {
			scrollToTop()
		}
		reportNavigationEnd(currentRoute, finalRouteInfo, InternalNavigationType.INNER_ROUTE, biReporter, logger)
	}

	let redirectCounter = 0

	const navigate = async (url: string, navigationParams?: NavigationParams): Promise<boolean> => {
		const fullUrlRouteWithQueryParams = convertToFullUrl(url, false)
		const fullUrlRouteWithoutQueryParams = convertToFullUrl(url, true)

		const currentRoute = currentRouteInfo.getCurrentRouteInfo() as CandidateRouteInfo
		const currentRouteUrlWithoutQueryParams = currentRoute && convertToFullUrl(currentRoute.parsedUrl.href, true)
		if (fullUrlRouteWithoutQueryParams === currentRouteUrlWithoutQueryParams) {
			currentRouteInfo.updateCurrentRouteInfo({ ...currentRoute, anchorDataId: navigationParams?.anchorDataId })
			urlHistoryManager.pushUrlState(new URL(fullUrlRouteWithQueryParams))
			scrollToTop()
			return false
		}

		let routeInfo: Partial<CandidateRouteInfo> | null = resolveUrl(
			convertToFullUrl(url, false),
			routingConfig,
			urlHistoryManager.getParsedUrl()
		)

		reportNavigationStart(currentRoute, routeInfo, biReporter, logger)

		routeInfo = customUrlMiddleware.handleSync(routeInfo)

		routeInfo = routeInfo && (await dynamicRoutingMiddleware.handle(routeInfo))

		if (routeInfo && routeInfo.redirectUrl) {
			if (redirectCounter < 4) {
				redirectCounter++
				reportNavigationEnd(
					currentRoute,
					routeInfo,
					InternalNavigationType.DYNAMIC_REDIRECT,
					biReporter,
					logger
				)
				return navigate(routeInfo.redirectUrl)
			}
			redirectCounter = 0
			reportNavigationEnd(currentRoute, routeInfo, InternalNavigationType.DYNAMIC_REDIRECT, biReporter, logger)
			return false
		} else {
			redirectCounter = 0
		}

		routeInfo = routeInfo && (await customNotFoundPageMiddleware.handle(routeInfo))
		routeInfo = routeInfo && (await pageJsonFileNameMiddleware.handle(routeInfo))
		routeInfo = routeInfo && (await BlockingDialogsRoutingMiddleware.handle(routeInfo))
		routeInfo = routeInfo && (await protectedRoutingMiddleware.handle(routeInfo))

		if (!routeInfo) {
			reportNavigationEnd(currentRoute, routeInfo, InternalNavigationType.NAVIGATION_ERROR, biReporter, logger)
			return false
		}

		if (!routeInfo.pageJsonFileName && protectedRoutingMiddleware !== emptyMiddleware) {
			reportNavigationEnd(currentRoute, routeInfo, InternalNavigationType.NAVIGATION_ERROR, biReporter, logger)
			throw new Error(`did not find the json file name for pageId ${routeInfo.pageId}`)
		}

		if (navigationParams?.anchorDataId) {
			routeInfo.anchorDataId = navigationParams?.anchorDataId
		}

		routeInfo.contextId = getContextId(routeInfo as CandidateRouteInfo)

		const finalRouteInfo = routeInfo as CandidateRouteInfo

		const isTpaSamePageNavigation =
			currentRoute?.pageId === finalRouteInfo.pageId &&
			routeInfo.type === 'Static' &&
			currentRoute?.type !== 'Dynamic'
		if (isTpaSamePageNavigation) {
			handleSamePageNavigation(currentRoute, finalRouteInfo, navigationParams)
			return false
		}

		if (navigationManager.isDuringNavigation()) {
			const shouldContinue = await navigationManager.waitForShouldContinueNavigation()
			const currentRouteFullUrl = convertToFullUrl(
				(currentRouteInfo.getCurrentRouteInfo() as CandidateRouteInfo).parsedUrl.href,
				true
			)
			if (!shouldContinue || fullUrlRouteWithoutQueryParams === currentRouteFullUrl) {
				reportNavigationEnd(currentRoute, finalRouteInfo, InternalNavigationType.CANCELED, biReporter, logger)
				return false
			}
		}

		navigationManager.startNavigation()
		if (currentRoute) {
			await notifyPageWillUnmount(currentRoute, pageProvider)
		}
		await handleStaticRoute(finalRouteInfo)
		reportNavigationEnd(currentRoute, finalRouteInfo, InternalNavigationType.NAVIGATION, biReporter, logger)
		return true
	}

	const isInternalRoute = (url: string): boolean => {
		let routeInfo = resolveUrl(url, routingConfig)
		routeInfo = customUrlMiddleware.handleSync(routeInfo) as Partial<CandidateRouteInfo>
		const { type, relativeUrl, pageId } = routeInfo
		switch (type) {
			case 'Dynamic':
				return true
			case 'Static':
				// Static page with inner route is supported only in tpa pages and wixapps app-builder AppPages
				return !!(
					relativeUrl!.split('/').length === 2 ||
					routingMasterPageConfig.tpaSectionPageIds[pageId!] ||
					routingMasterPageConfig.appBuilderAppPageIds[pageId!]
				)
			default:
				return false
		}
	}

	return {
		navigate,
		isInternalRoute,
	}
}

export const Router = withDependencies(
	[
		named(SiteFeatureConfigSymbol, name),
		named(MasterPageFeatureConfigSymbol, name),
		StructureAPI,
		PageProviderSymbol,
		NavigationManagerSymbol,
		optional(PageJsonFileNameMiddlewareSymbol),
		optional(CustomNotFoundPageMiddlewareSymbol),
		optional(CustomUrlMiddlewareSymbol),
		optional(RoutingMiddleware.Dynamic),
		optional(RoutingMiddleware.Protected),
		optional(RoutingMiddleware.BlockingDialogs),
		PageInitializerSymbol,
		multi(LifeCycle.AppWillRenderFirstPageHandler),
		CurrentRouteInfoSymbol,
		UrlHistoryManagerSymbol,
		BISymbol,
		LoggerSymbol,
		BrowserWindowSymbol,
	],
	routerFactory
)
