export const HeadContentSymbol = Symbol.for('HeadContentSymbol')
export const HeadProviderSym = Symbol.for('HeadProviderSym')
export const HeadModelCalculatorSym = Symbol('HeadModelCalculator')
export const HeadersCalculatorSym = Symbol('HeadersCalculator')
export const LinkTagsCalculatorSym = Symbol('LinkTagsCalculator')
export const TemplateRendererSym = Symbol('TemplateRenderer')
export const SiteModelSym = Symbol('SiteModel')
export const ModulesLoaderSym = Symbol('ModulesLoader')
export const SsrModelsBuilderSym = Symbol('SsrModelsBuilder')
export const TbElementsCalculatorSym = Symbol('TbElementsCalculator')
export const FeaturesChunksExtractorSym = Symbol('FeaturesChunksExtractor')
export const ComponentsRegistryCalculatorSym = Symbol('ComponentsRegistryCalculatorSym')
